import { default as _91_46_46_46slug_93d89gBJciv9Meta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/[...slug].vue?macro=true";
import { default as autologiniWQkM6ghvnMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/auth/autologin.vue?macro=true";
import { default as callback3AdIjN0yY6Meta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/auth/callback.vue?macro=true";
import { default as betting3NmXhy9EdhMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/betting.vue?macro=true";
import { default as _91pageIdentity_93eWvLOu4FINMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/bonus/[pageIdentity].vue?macro=true";
import { default as contacte7JndNIRoIMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/contact.vue?macro=true";
import { default as favorites_46clientDB5jI5PbJTMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/favorites.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/platform/customer-frontends/winlion/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91id_93Bz7JtEmv7CMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/games/[id].vue?macro=true";
import { default as indexlkfO2dzq9EMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/games/index.vue?macro=true";
import { default as indexx1RWZKiym3Meta } from "/builds/platform/customer-frontends/winlion/pages/index.vue?macro=true";
import { default as loyaltyGRLFRr9s3JMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/loyalty.vue?macro=true";
import { default as mainc8uKDI3NyJMeta } from "/builds/platform/customer-frontends/winlion/pages/main.vue?macro=true";
import { default as bonuses02M621GqDaMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/profile/bonuses.vue?macro=true";
import { default as documentsHUYdxiv8c2Meta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/profile/documents.vue?macro=true";
import { default as historykReR4oz1BNMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/profile/history.vue?macro=true";
import { default as infokkBAWl0bQiMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/profile/info.vue?macro=true";
import { default as limitsxRplOurd0zMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/profile/limits.vue?macro=true";
import { default as notificationsSLVAoGShUZMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/profile/notifications.vue?macro=true";
import { default as securitynZl881EFiCMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/profile/security.vue?macro=true";
import { default as verificationxSiyCl8xCPMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/profile/verification.vue?macro=true";
import { default as walletMNNO3o9m00Meta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/profile/wallet.vue?macro=true";
import { default as profile_46clientmEJOZ80vHJMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/profile.client.vue?macro=true";
import { default as providersIHqf9jnZvxMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/providers.vue?macro=true";
import { default as _91pageIdentity_9389qPyjysW6Meta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/questions/[pageIdentity].vue?macro=true";
import { default as questionsgEVX90YKM1Meta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/questions.vue?macro=true";
import { default as recently_45played_46clientS61kZxDtfaMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/recently-played.client.vue?macro=true";
import { default as _91pageIdentity_93OJ3eSDRQ4PMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/static/[pageIdentity].vue?macro=true";
import { default as welcome_45package8oCpuO8hohMeta } from "/builds/platform/customer-frontends/winlion/skeleton/pages/welcome-package.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/[...slug].vue")
  },
  {
    name: "auth-autologin",
    path: "/auth/autologin",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/auth/autologin.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/auth/callback.vue")
  },
  {
    name: "betting",
    path: "/betting",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/betting.vue")
  },
  {
    name: "bonus-pageIdentity",
    path: "/bonus/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/bonus/[pageIdentity].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/contact.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/winlion/skeleton/pages/favorites.client.vue"))
  },
  {
    name: "games-id",
    path: "/games/:id()",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/games/[id].vue")
  },
  {
    name: "games",
    path: "/games",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/games/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/index.vue")
  },
  {
    name: "loyalty",
    path: "/loyalty",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/loyalty.vue")
  },
  {
    name: "main",
    path: "/main",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/main.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/winlion/skeleton/pages/profile.client.vue")),
    children: [
  {
    name: "profile-bonuses",
    path: "bonuses",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/profile/bonuses.vue")
  },
  {
    name: "profile-documents",
    path: "documents",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/profile/documents.vue")
  },
  {
    name: "profile-history",
    path: "history",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/profile/history.vue")
  },
  {
    name: "profile-info",
    path: "info",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/profile/info.vue")
  },
  {
    name: "profile-limits",
    path: "limits",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/profile/limits.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/profile/notifications.vue")
  },
  {
    name: "profile-security",
    path: "security",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/profile/security.vue")
  },
  {
    name: "profile-verification",
    path: "verification",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/profile/verification.vue")
  },
  {
    name: "profile-wallet",
    path: "wallet",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/profile/wallet.vue")
  }
]
  },
  {
    name: "providers",
    path: "/providers",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/providers.vue")
  },
  {
    name: "questions",
    path: "/questions",
    meta: questionsgEVX90YKM1Meta || {},
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/questions.vue"),
    children: [
  {
    name: "questions-pageIdentity",
    path: ":pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/questions/[pageIdentity].vue")
  }
]
  },
  {
    name: "recently-played",
    path: "/recently-played",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/winlion/skeleton/pages/recently-played.client.vue"))
  },
  {
    name: "static-pageIdentity",
    path: "/static/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/static/[pageIdentity].vue")
  },
  {
    name: "welcome-package",
    path: "/welcome-package",
    component: () => import("/builds/platform/customer-frontends/winlion/skeleton/pages/welcome-package.vue")
  }
]